import React from 'react'
import NewsLetter from './NewsLetter'
import { Link } from 'react-router-dom';
import img_whatsapp_image_2024_07_30 from '../public/images/img_whatsapp_image_2024_07_30.webp';
import img_checkmark from '../public/images/img_checkmark.svg';
import img_whatsapp_image_2024_07_30_472x516 from '../public/images/img_whatsapp_image_2024_07_30_472x516.webp';
import img_globe from '../public/images/img_globe.svg';
import img_start_art from '../public/images/img_start_art.svg';
import img_inspirational_env from '../public/images/img_inspirational_env.svg';
import prashanth from '../public/images/Prashanth.webp';
import vani from '../public/images/Vani_Sharma.webp';
import manju from '../public/images/Manju.webp';

function AboutUs() {
  return (
    <div>
      {/* hero banner section */}
      <div
        className="about-banner flex h-[404px] items-start justify-center border-b border-solid border-color_1 bg-color_3 bg-cover bg-no-repeat py-[60px] md:h-auto md:py-5">
        <div className="container-xs mb-4 flex justify-center md:px-5">
          <div className="w-full">
            <div className="flex items-center justify-between gap-5 md:flex-col">
              <img src={img_whatsapp_image_2024_07_30} alt="About banner"
                className="h-[266px] w-[42%] rounded-[36px] object-contain md:w-full" />
              <div className="flex w-[48%] flex-col items-start md:items-center md:w-full">
                <h1 className="size-heading5xl heading ui !font-poppins !text-primary sm:text-[40px]">
                  <span className="text-color_4"> About&nbsp;</span>
                  <span className="text-secondary-0"> Us</span>
                </h1>
                <div className="flex flex-wrap">
                  <Link to="/"
                    className="size-text2xl text ui !font-poppins !font-medium !text-color_4 mr-[0.5px]">Home -</Link>
                  <Link to="/aboutus"
                    className="size-text2xl text ui !font-poppins !font-medium !text-secondary-0">About</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* about section */}
      <div>
        <div className="flex flex-col items-center bg-color_3 py-[34px] sm:py-5">
          <div className="container-xs mt-9 md:px-5">
            <div>
              <div className="flex items-center gap-[22px] md:flex-col">
                <div className="flex flex-1 flex-col gap-6 md:self-stretch">
                  <h2
                    className="size-heading4xl heading ui !font-poppins leading-[115%] !text-black-900_01 sm:text-[38px] text-left md:text-center">
                    Elevate Your Music Learning Experiences with Mufin Academy
                  </h2>
                  <div className="flex flex-col gap-3">
                    <h3 className="size-textlg heading ui w-[82%] !font-asap !font-normal leading-[150%] md:w-full text-left">
                      At Mufin Academy, we demonstrate our passion for music and foster a nurturing environment to
                      enable students to hone their music & dance abilities. The academy was founded on 1st Dec 2016
                      after which it became a hotspot for individuals aspiring to make a mark irrespective of age and
                      skills. We understand that every person can express themselves through music & empower them at
                      every step of their journey. With a strong emphasis on personalization, the goal is to make music
                      education accessible and game-changing for all.
                    </h3>
                    <div className="flex flex-col gap-1.5">
                      <div className="flex items-center gap-[15px]">
                        <img src={img_checkmark} alt="Checkmark Icon 1" className="h-[12px]" />
                        <h5 className="size-textlg heading ui !font-asap !font-normal !text-color_2 text-left">
                          Transform from beginner to master with instructors who have performed internationally.
                        </h5>
                      </div>
                      <div className="flex items-center gap-[15px]">
                        <img src={img_checkmark} alt="Checkmark Icon 2" className="h-[12px]" />
                        <h6 className="size-textlg heading ui !font-asap !font-normal !text-color_2">
                          Spotlight our diversified courses crafted to your requirements & goals.
                        </h6>
                      </div>
                      <div className="flex items-center gap-[15px]">
                        <img src={img_checkmark} alt="Checkmark Icon 3" className="h-[12px]" />
                        <p className="size-textlg heading ui !font-asap !font-normal !text-color_2">
                          Boost your confidence and experience by participating in our events.
                        </p>
                      </div>
                      <div className="flex items-center gap-[15px]">
                        <img src={img_checkmark} alt="Checkmark Icon 4" className="h-[12px]" />
                        <p className="size-textlg heading ui !font-asap !font-normal !text-color_2">Get direct access to
                          music scholarships & professional networks.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <img src={img_whatsapp_image_2024_07_30_472x516} alt="Kids singing"
                  className="h-[472px] w-[50%] rounded-[18px] object-contain md:w-full" />
              </div>
            </div>
            <div className="mt-8 flex gap-[22px] md:flex-col">
              <div className="flex w-[32%] rounded-[18px] bg-secondary-0 p-3.5 md:w-full">
                <div className="mb-3.5 flex w-full flex-col items-center gap-2">
                  <h4 className="size-headingxl heading ui !font-nunito !text-color_3">Courses</h4>
                  <div className="flex items-start gap-[22px] self-stretch">
                    {/* <div className="h-[66px] w-[66px] self-center rounded-[32px] bg-gray-400"></div> */}
                    <div className="mt-1.5 flex flex-1 flex-col items-center">
                      <h6 className="size-headingmd heading ui !font-nunito !text-color_3 font-medium">30+ Available Courses</h6>
                      {/* <p className="size-headings heading ui !font-nunito !text-color_6">Environmental Advocate</p> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-[32%] rounded-[18px] bg-secondary-0 p-3.5 md:w-full">
                <div className="mb-3.5 flex w-full flex-col items-center gap-2">
                  <h4 className="size-headingxl heading ui !font-nunito !text-color_3">Students</h4>
                  <div className="flex items-start gap-[22px] self-stretch">
                    {/* <div className="h-[66px] w-[66px] self-center rounded-[32px] bg-gray-400"></div> */}
                    <div className="mt-1.5 flex flex-1 flex-col items-center">
                      <h6 className="size-headingmd heading ui !font-nunito !text-color_3">30,000+ Trained Students</h6>
                      {/* <p className="size-headings heading ui !font-nunito !text-color_6">Environmental Advocate</p> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-[32%] rounded-[18px] bg-secondary-0 p-3.5 md:w-full">
                <div className="mb-3.5 flex w-full flex-col items-center gap-2">
                  <h4 className="size-headingxl heading ui !font-nunito !text-color_3">Performance</h4>
                  <div className="flex items-start gap-[22px] self-stretch">
                    {/* <div className="h-[66px] w-[66px] self-center rounded-[32px] bg-gray-400"></div> */}
                    <div className="mt-1.5 flex flex-1 flex-col items-center">
                      <h6 className="size-headingmd heading ui !font-nunito !text-color_3">45+ Stage Performances</h6>
                      {/* <p className="size-headings heading ui !font-nunito !text-color_6">Environmental Advocate</p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p className="size-textlg heading ui mt-[52px] !font-asap !font-normal leading-[150%] text-center">
              The idea behind Mufin Academy originated from the founders' desire to train their children in guitar and
              keyboard. Like other parents, they wanted their child to be an all-rounder, so they started searching for
              an academy where their child could enrich their creativity and become better prepared for the future.
            </p>
            <p className="size-textlg heading ui mt-[15px] !font-asap !font-normal leading-[150%] text-center">
              When they were unable to find a reputed place that would give them confidence in the training content and
              instructors, they had an interesting problem to solve.
            </p>
            <p className="size-textlg heading ui mt-[15px] !font-asap !font-normal leading-[150%] text-center">
              On the journey, they discovered that most institutes were away or joining them was a safety concern. Also,
              these became a pain point for many individuals desirous of providing quality training. To mitigate the
              gap, they came up with a unique idea, which is "Mufin Academy" now!
            </p>
          </div>
        </div>
      </div>

      {/* event highlights section */}
      <div className="bg-color_3 py-[52px] md:pb-[30px] md:pt-[20px]">
        <div className="mb-3.5 flex justify-center px-14 md:px-5">
          <div className="flex w-[82%] items-center justify-between gap-5 md:w-full md:flex-col">
            <div className="ml-[22px] flex w-[48%] flex-col gap-[22px] self-end md:ml-0 md:w-full">
              <div className="flex flex-1 rounded-[18px] bg-color_4 p-6 shadow-xs sm:p-5">
                <div className="flex w-full flex-col items-start">
                  <div className="flex items-start gap-4 self-stretch">
                    <button
                      className="circle fill size-sm color_6 button ui h-[36px] w-[36px] !rounded-[18px] !bg-color_6 px-1.5">
                      <img src={img_globe} alt="Dynamic Networking Prospects" />
                    </button>
                    <h2 className="size-headinglg heading ui self-center !font-nunito !text-secondary-0 sm:text-[23px]">
                      Dynamic Networking Prospects
                    </h2>
                  </div>
                  <p className="size-texts text ui !font-asap !text-color_5 w-full pl-[35px] text-left">
                    Get access to dynamic networking prospects at our live events.
                  </p>
                </div>
              </div>
              <div className="flex flex-1 gap-3.5 rounded-[18px] bg-color_4 p-6 shadow-xs sm:p-5">
                <div className="flex w-full flex-col items-start">
                  <div className="flex items-start gap-4 self-stretch">
                    <button
                      className="circle fill size-sm color_6 button ui h-[36px] w-[36px] !rounded-[18px] !bg-color_6 px-1.5">
                      <img src={img_start_art} alt='State of the Art Facilities' />
                    </button>
                    <h4 className="size-headinglg heading ui self-center !font-nunito !text-secondary-0 sm:text-[23px]">
                      State of the Art Facilities
                    </h4>
                  </div>
                  <p className="size-texts text ui !font-asap !text-color_5 pl-[35px]">
                    We provide top-notch facilities to hone your abilities on the professional front.
                  </p>
                </div>
              </div>
              <div className="flex flex-1 gap-3.5 rounded-[18px] bg-color_4 p-6 shadow-xs sm:p-5">
                <div className="flex w-full flex-col items-start">
                  <div className="flex items-start gap-4 self-stretch">
                    <button
                      className="circle fill size-sm color_6 button ui h-[36px] w-[36px] !rounded-[18px] !bg-color_6 px-1.5">
                      <img src={img_inspirational_env} alt='Inspirational Culture' />
                    </button>
                    <h4 className="size-headinglg heading ui self-center !font-nunito !text-secondary-0 sm:text-[23px]">
                      Inspirational Culture
                    </h4>
                  </div>
                  <p className="size-texts text ui !font-asap !text-color_5 pl-[35px]">
                    Our team inspires you at every step of the journey to help you thrive.
                  </p>
                </div>
              </div>
            </div>
            <div className="mb-1.5 flex w-[42%] flex-col items-start gap-2.5 md:w-full md:pt-[30px]">
              <h5 className="size-headinglg heading ui !font-poppins !text-secondary-0 md:block md:text-center md:w-full">
                Join Us</h5>
              <h6
                className="size-heading4xl heading ui w-full !font-poppins leading-[115%] !text-color_2 sm:text-[38px] text-left md:text-center">
                Mufin Academy: Your Gateway to Musical Mastery
              </h6>
              <p className="size-text2xl text ui w-full !font-asap leading-[150%] !text-color_5 text-left md:text-center">
                Embrace the path of musical mastery with renowned professionals. We are there to guide you at every step
                to help you accomplish your goals.
              </p>
              <div className="self-stretch">
                <div className="flex flex-col gap-4 mt-3">
                  <div className="flex">
                    <div className="flex w-[35%] items-start justify-center gap-3.5 sm:w-full">
                      <div className="h-[72px] w-px self-center bg-gray-600_3f"></div>
                      <div className="flex flex-1 flex-col items-start">
                        <h1 className="size-headingxl heading ui !font-nunito !text-secondary-0 sm:text-[32px]">30,000+</h1>
                        <p className="size-headingxs heading ui !font-nunito !text-color_2">Transformed Artists</p>
                      </div>
                    </div>
                    <div className="flex flex-1 items-start justify-center gap-5 px-6 sm:self-stretch sm:px-5">
                      <div className="h-[72px] w-px bg-gray-600_3f"></div>
                      <div className="flex flex-1 flex-col items-start">
                        <h1 className="size-headingxl heading ui !font-nunito !text-secondary-0 sm:text-[32px]">10,00,000+
                        </h1>
                        <p className="size-headingxs heading ui !font-nunito !text-color_2">Dynamic Live classNamees</p>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-start justify-start">
                    <div className="flex w-[35%] items-start justify-center gap-3.5 sm:w-full">
                      <div className="mt-1 h-[72px] w-px self-end bg-gray-600_3f"></div>
                      <div className="flex flex-1 flex-col items-start">
                        <h1 className="size-headingxl heading ui !font-nunito !text-secondary-0 sm:text-[32px]">4.8/5</h1>
                        <p className="size-headingxs heading ui !font-nunito !text-color_2">Global</p>
                      </div>
                    </div>
                    {/* <div className="flex flex-1 items-center justify-center gap-5 px-6 sm:self-stretch sm:px-5">
                      <div className="h-[72px] w-px bg-gray-600_3f"></div>
                      <div className="flex flex-1 flex-col items-start">
                        <h1 className="size-heading3xl heading ui !font-nunito !text-secondary-0 sm:text-[32px]">350+</h1>
                        <p className="size-headings heading ui !font-nunito !text-color_2">Journalist</p>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* speaker section */}
      <div>
        <div className="flex flex-col items-center bg-color_3 py-[70px] md:py-5">
          <div className="container-xs mb-[30px] flex flex-col gap-[22px] md:px-5">
            <div className="ml-[122px] mr-[124px] flex flex-col items-center md:mx-0">
              <h2 className="size-headinglg heading ui !font-nunito !text-secondary-0">Meet Our Certified Professionals</h2>
              <h3 className="size-heading4xl heading ui text-center !font-nunito !text-gray-900 sm:text-[38px]">
                Industry Experts Trusted by Millions Globally!
              </h3>
              <p className="size-text2xl text ui mt-2 self-stretch text-center !font-asap leading-[150%] !text-color_5">
                Learn about our talented faculty, who have been the guiding force of numerous individuals. Every
                instructor guided these future musicians with a vision to nurture them.
              </p>
            </div>
            <div className="flex gap-5 md:flex-col">
              <div className="flex w-[32%] flex-col md:w-full">
                <div>
                  <div className="h-[396px] rounded-[18px] bg-color_1 overflow-hidden">
                    <img src={prashanth} alt="Prashanth Kumar Kandikonda" className='w-full object-cover' />
                  </div>
                  <div
                    className="relative mx-[18px] mt-[-42px] flex flex-col items-center justify-center rounded-[10px] border-b-2 border-solid border-light_blue-a700 bg-color_4 p-3.5 shadow-xs md:mx-0">
                    <h4 className="size-headinglg heading ui !font-nunito !text-light_blue-a700">
                        Prashanth Kumar Kandikonda
                    </h4>
                    <h5 className="size-headingxs heading ui !font-nunito">Sound Engineer, 8 Years Experience</h5>
                  </div>
                </div>
              </div>
              <div className="flex w-[32%] flex-col md:w-full">
                <div>
                  <div className="h-[396px] rounded-[18px] bg-color_1 overflow-hidden">
                    <img src={vani} alt="Krishnam Shiva Vani Sharma" className='w-full object-cover' />
                  </div>
                  <div
                    className="relative mx-[18px] mt-[-42px] flex flex-col items-center justify-center rounded-[10px] border-b-2 border-solid border-light_blue-a700 bg-color_4 p-3.5 shadow-xs md:mx-0">
                    <h6 className="size-headinglg heading ui !font-nunito !text-light_blue-a700">
                        Krishnam Shiva Vani Sharma
                    </h6>
                    <p className="size-headingxs heading ui !font-nunito">Teacher, 8 Years Experience</p>
                  </div>
                </div>
              </div>
              <div className="flex w-[32%] flex-col md:w-full">
                <div>
                  <div className="h-[396px] rounded-[18px] bg-color_1 overflow-hidden">
                    <img src={manju} alt="Manju Latha Mandapakala" className='w-full object-cover' />
                  </div>
                  <div
                    className="relative mx-[18px] mt-[-42px] flex flex-col items-center justify-center rounded-[10px] border-b-2 border-solid border-light_blue-a700 bg-color_4 p-3.5 shadow-xs md:mx-0">
                    <h6 className="size-headinglg heading ui !font-nunito !text-light_blue-a700">Manju Latha Mandapakala</h6>
                    <p className="size-headingxs heading ui !font-nunito">Founder and Teacher, 16 Years Experience</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* newsletter signup section */}
      <NewsLetter />
    </div>
  )
}

export default AboutUs