import React from 'react'
import img_44_800x600_0_1 from '../public/images/img_44_800x600_0_1.webp';
import img_frame_light_blue_900 from '../public/images/img_frame_light_blue_900.svg';

function CourseDetails() {
    return (
        <div>
            {/* course overview section */}
            <div className="container-xs px-12 md:px-5">
                <div className="flex flex-col gap-[22px] p-2.5">
                    <div className="flex">
                        <h1 className="size-text5xl heading ui !font-poppins capitalize !text-secondary-0 sm:text-[24px]">
                            Piano Foundation
                        </h1>
                    </div>
                    <div className="mb-[42px] flex items-start gap-[30px] md:flex-col">
                        <div className="flex flex-1 flex-col gap-8 self-center md:self-stretch">
                            <img src={img_44_800x600_0_1} alt="About The Course"
                                className="mr-2 h-[270px] rounded-lg object-cover md:mr-0" />
                            <div className="flex flex-col items-start">
                                <div className="flex self-stretch">
                                    <h2 className="size-headinglg heading ui !font-opensans !font-semibold !text-light_blue-900">
                                        About The Course
                                    </h2>
                                </div>
                                <div className="flex flex-col items-start justify-center self-stretch mt-[10px]">
                                    <p className="size-text2xl text ui w-full leading-[21px] text-left">
                                        If your child is curious about playing the piano, our Spardha Piano Foundation course is the
                                        perfect place to begin their musical adventure. We&#39;re not just here to teach; we&#39;re here
                                        to ignite their love for music.
                                    </p>
                                    <p className="size-text2xl text ui w-[98%] leading-[21px] md:w-full text-left">
                                        In this course, our friendly and caring teachers guide your child through the foundational
                                        concepts of playing the piano. They learn how to read sheet music, play the right way, and pick up
                                        important music skills. It&#39;s not just about learning; it&#39;s about nurturing a passion for
                                        music.
                                    </p>
                                    <p className="size-text2xl text ui w-full leading-[21px] text-left">
                                        We will help the students understand things like beats, rhythm, dynamics, and time signatures.
                                        Your child also learns chords and the basics of music theory. Our goal is to turn their interest
                                        into a lifelong passion for playing the piano.
                                    </p>
                                </div>
                                <h3 className="size-text3xl heading ui mt-[18px] !font-poppins !text-light_blue-900">Prerequisites</h3>
                                <div className="self-stretch text-left">
                                    <ul className="m-0 list-disc mt-[5px] pl-[50px] about-course-list">
                                        <li className="size-text2xl text ui">Interest and willingness to learn the Piano.</li>
                                        <li className="size-text2xl text ui">A piano</li>
                                    </ul>
                                </div>
                                <div className="mt-3 flex self-stretch">
                                    <h4 className="size-text3xl heading ui !font-poppins !text-light_blue-900">Topics Covered</h4>
                                </div>
                                <div className="self-stretch text-left">
                                    <ul className="m-0 list-disc mt-[5px] pl-[50px] about-course-list">
                                        <li className="size-text2xl text ui">Basic Elements of Piano (Keys & Notes)</li>
                                        <li className="size-text2xl text ui">Posture and fingers</li>
                                        <li className="size-text2xl text ui">Beats, rhythm & time signature</li>
                                        <li className="size-text2xl text ui">Concepts like dynamics, slurs, tie notes and dotted rhythms</li>
                                        <li className="size-text2xl text ui">Staff notations (Clef, Notes values, etc.)</li>
                                        <li className="size-text2xl text ui">Learn songs in Middle 'C', 'C' and 'G' Position</li>
                                        <li className="size-text2xl text ui">Chords & basic music theory</li>
                                    </ul>
                                </div>
                                <div className="mt-3 flex self-stretch">
                                    <h5 className="size-text3xl heading ui !font-poppins !text-light_blue-900">
                                        Learning Outcomes You Can Expect
                                    </h5>
                                </div>
                                <div className="self-stretch text-left">
                                    <ul className="m-0 list-disc mt-[5px] pl-[50px] about-course-list">
                                        <li className="size-text2xl text ui">Identify notes on the instrument</li>
                                        <li className="size-text2xl text ui">Play the basic rhythms and pieces with steady pulse and flow</li>
                                        <li className="size-text2xl text ui">Read sheet music and staff notations effectively</li>
                                        <li className="size-text2xl text ui">Understand the basic touch controls in playing</li>
                                        <li className="size-text2xl text ui">Play different rhythm techniques</li>
                                        <li className="size-text2xl text ui">Tune 2 and 4 notes harmonies with the use of ties and dotted
                                            rhythms.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="flex w-[32%] flex-col gap-6 md:w-full">
                            <div>
                                <div className="rounded-md bg-gray-100_01">
                                    <div className="flex flex-col gap-2 bg-gray-100 p-7 sm:p-5">
                                        <div className="flex flex-col gap-3 items-start">
                                            <h6 className="size-textlg heading ui bg-gray-100 !font-poppins !font-normal !text-light_blue-900">
                                                English
                                            </h6>
                                            <p
                                                className="size-textlg heading ui flex bg-gray-100 !font-poppins !font-normal !text-light_blue-900">
                                                Format - 1:1 lesson with Teacher
                                            </p>
                                            <p className="size-textlg heading ui bg-gray-100 !font-poppins !font-normal !text-light_blue-900">
                                                Foundation
                                            </p>
                                            <p className="size-textlg heading ui bg-gray-100 !font-poppins !font-normal !text-light_blue-900">
                                                Mobile & Desktop Access
                                            </p>
                                            <p className="size-textlg heading ui bg-gray-100 !font-poppins !font-normal !text-light_blue-900">
                                                Certificate on completion
                                            </p>
                                            <p className="size-textlg heading ui bg-gray-100 !font-poppins !font-normal !text-light_blue-900">
                                                Duration: 6-8 months
                                            </p>
                                        </div>
                                        <div className="flex flex-col gap-2">
                                            <button
                                                className="round bordered size-xs light_blue_900 button ui h-[36px] self-stretch !rounded !border-[0.75px] !border-solid !border-light_blue-900 px-[33.25px] font-poppins text-[12px] capitalize !text-light_blue-900 sm:px-5">
                                                Know the Pricing
                                            </button>
                                            <button
                                                className="round fill size-xs teal_a400 button ui h-[36px] self-stretch !rounded !bg-teal-a400 px-[34px] font-poppins text-[12px] font-bold uppercase !text-primary sm:px-5">
                                                BOOK NOW
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="flex flex-col gap-3.5 rounded-md border-[0.75px] border-solid border-gray-200 bg-primary p-3.5">
                                <div className="mr-2.5 flex md:mr-0">
                                    <p className="size-textxl text ui w-[56%] !font-poppins capitalize leading-[21px] !text-light_blue-900">
                                        Related Courses
                                    </p>
                                </div>
                                <div className="mb-3 flex flex-col gap-3.5">
                                    <div className="flex flex-1 justify-start gap-2 rounded bg-gray-100_01 p-2 px-4">
                                        <img src={img_frame_light_blue_900} alt="Electronic Keyboard Foundation" className="h-[18px]" />
                                        <p className="size-texts text ui self-end capitalize !text-blue_gray-900">
                                            Electronic Keyboard Foundation
                                        </p>
                                    </div>
                                    <div className="flex flex-1 justify-start gap-2 rounded bg-gray-100_01 p-2 px-4">
                                        <img src={img_frame_light_blue_900} alt="Acoustic Guitar Foundation" className="h-[18px]" />
                                        <p className="size-texts text ui self-end capitalize !text-blue_gray-900">
                                            Acoustic Guitar Foundation
                                        </p>
                                    </div>
                                    <div className="flex flex-1 justify-start gap-2 rounded bg-gray-100_01 p-2 px-4">
                                        <img src={img_frame_light_blue_900} alt="Spardha Western Vocals Foundation" className="h-[18px]" />
                                        <p className="size-texts text ui self-end capitalize !text-blue_gray-900">
                                            Spardha Western Vocals Foundation
                                        </p>
                                    </div>
                                    <div className="flex flex-1 justify-start gap-2 rounded bg-gray-100_01 p-2 px-4">
                                        <img src={img_frame_light_blue_900} alt="Spardha Bharathanatyam Foundation" className="h-[18px]" />
                                        <p className="size-texts text ui self-end capitalize !text-blue_gray-900">
                                            Spardha Bharathanatyam Foundation
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CourseDetails