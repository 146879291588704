import './App.css';
import './css/index.css';
import './css/font.css';
import './css/main.css';
import { createBrowserRouter, RouterProvider, createRoutesFromElements, Route } from "react-router-dom";
import RootLayout from './components/RootLayout';
import Courses from './components/Courses';
import Home from './components/Home';
import AboutUs from './components/AboutUs';
import CourseDetails from './components/CourseDetails';
import ContactUs from './components/ContactUs';
import $ from 'jquery';
import "jquery-ui-dist/jquery-ui";
import { useEffect } from 'react';

function App() {

  useEffect(() => {
    $('.hamburger-btn').on('click', function () {
      $('.mobile-menu').addClass('active');
      $('body').addClass('active');
    });
    return () => {
    }
  }, [])

  useEffect(() => {
    $('.close-menu-btn').on('click', function () {
      $('.mobile-menu').removeClass('active');
      $('body').removeClass('active');
    });
  }, [])

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<RootLayout />}>
        <Route index element={<Home />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/coursedetails" element={<CourseDetails />} />
        <Route path="/contactus" element={<ContactUs />} />
      </Route>
    )
  );

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
