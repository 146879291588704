import React from 'react'
import $ from 'jquery';
import "jquery-ui-dist/jquery-ui";
import { Link } from 'react-router-dom';
import img_1_6116 from '../public/images/img_1_6116.webp';
import img_2 from '../public/images/img_2.webp';
import img_1 from '../public/images/img_1.webp';
import img_4 from '../public/images/img_4.webp';
import img_3 from '../public/images/img_3.webp';
import img_5 from '../public/images/img_5.webp';
import img_6 from '../public/images/img_6.webp';
import bollywood from '../public/images/bollywood.webp';
import western from '../public/images/western.webp';
import carnatic from '../public/images/carnatic.webp';
import hindustani from '../public/images/hindustani.webp';
import telugu from '../public/images/telugu.webp';

function Courses() {

  const funcInstrument = () => {
    $('.instruments-tab').addClass('active');
    $('.dance-tab').removeClass('active');
    $('.vocals-tab').removeClass('active');
    $('.tab-1').addClass('active');
    $('.tab-2').removeClass('active');
    $('.tab-3').removeClass('active');
  }
  const funcVocal = () => {
    $('.instruments-tab').removeClass('active');
    $('.dance-tab').removeClass('active');
    $('.vocals-tab').addClass('active');
    $('.tab-2').addClass('active');
    $('.tab-1').removeClass('active');
    $('.tab-3').removeClass('active');
  }
  const funcDance = () => {
    $('.instruments-tab').removeClass('active');
    $('.dance-tab').addClass('active');
    $('.vocals-tab').removeClass('active');
    $('.tab-3').addClass('active');
    $('.tab-2').removeClass('active');
    $('.tab-1').removeClass('active');
  }

  return (
    <div>
      {/* hero banner section */}
      <div>
        <div className="border-b border-solid border-color_1 bg-color_3">
          <div
            className="courses-banner flex h-[404px] items-start justify-center bg-cover bg-no-repeat py-[60px] md:h-auto md:py-5">
            <div className="container-xs mb-4 flex justify-center md:px-5">
              <div className="w-full">
                <div className="flex items-start justify-between gap-5 md:flex-col">
                  <img src={img_1_6116} alt="Course"
                    className="h-[266px] w-[42%] self-center rounded-[18px] object-contain md:w-full" />
                  <div className="mt-[74px] md:mt-[0] flex w-[48%] flex-col items-start md:items-center md:w-full">
                    <h1 className="size-heading5xl heading ui !font-nunito !text-secondary-0 sm:text-[40px]">
                      <span className="text-color_4">Our&nbsp;</span>
                      <span className="text-secondary-0">Courses</span>
                    </h1>
                    <div className="flex flex-wrap">
                      <Link to="/"
                        className="size-text2xl text ui !font-poppins !font-medium !text-color_4 mr-[0.5px]">Home -</Link>
                      <Link to="/courses"
                        className="size-text2xl text ui !font-poppins !font-medium !text-secondary-0">Courses</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* courses overview section */}
      <div>
        <div className="flex justify-center bg-primary py-[46px] md:py-5">
          <div className="container-xs mt-3 flex justify-center px-14 md:px-5">
            <div className="flex w-full flex-col gap-8">
              <div className="flex flex-col items-center">
                <h2
                  className="size-heading3xl heading ui !font-poppins !font-semibold capitalize !text-indigo-800 sm:text-[32px]">
                  See All Our Online Music Courses
                </h2>
                <h3
                  className="size-text4xl heading ui relative mb-2 mt-[-8px] text-center !font-poppins capitalize leading-[54px] !text-light_blue-a700 sm:text-[23px]">
                  And Pick One Or More That Interests You The Most
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* tabs */}
      <div className="tabs-section pb-[80px]">
        <div className="flex justify-center bg-primar">
          <div className="container-xs mt-3 flex px-14 md:px-5">
            <div className="flex flex-col">
              <div x-data="{ openTab: 1 }">
                <div className="tabs-wrap">
                  {/* tabs button */}
                  <div
                    className="flex gap-1.5 rounded-md border-[0.75px] border-solid border-gray-200 bg-primary md:flex-col space-x-4 p-2 mb-10 shadow-md">
                    <button
                      className="instruments-tab tab-btn active round bordered size-lg light_blue_900 button ui h-[42px] w-full !rounded-[3px] !border-[0.75px] !border-solid !border-light_blue-900 px-[33.25px] font-poppins text-[16px] font-medium !text-light_blue-900 md:px-5" onClick={funcInstrument}>
                      Instruments
                    </button>
                    <button
                      className="vocals-tab tab-btn round bordered size-lg light_blue_900 button ui h-[42px] w-full !rounded-[3px] !border-[0.75px] !border-solid !border-light_blue-900 px-[33.25px] font-poppins text-[16px] font-medium !text-light_blue-900 md:px-5 md:mt-1" onClick={funcVocal}>
                      Vocals
                    </button>
                    <button
                      className="dance-tab tab-btn round bordered size-lg light_blue_900 button ui h-[42px] w-full !rounded-[3px] !border-[0.75px] !border-solid !border-light_blue-900 px-[33.25px] font-poppins text-[16px] font-medium !text-light_blue-900 md:px-5 md:mt-1 disabled" onClick={funcDance}>
                      Dance
                    </button>
                  </div>
                  {/* tabs content */}
                  <div className="transition-all duration-300 tab-1 active">
                    <div className="w-[100%] grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-4">
                      <div className="flex flex-col border-[0.75px] border-solid border-blue-100 bg-gray-100 md:w-full">
                        <img src={img_2} alt="Electronic Keyboard" className="h-[120px] object-cover" />
                        <div className="mb-3 flex flex-col items-start justify-between h-full md:items-center">
                          <div
                            className="flex flex-col items-start gap-1.5 self-stretch px-3.5 py-[18px] h-full">
                            <div className="flex self-stretch">
                              <p
                                className="size-textxl text ui w-[82%] md:w-full md:text-center !font-poppins leading-[21px] !text-light_blue-900 text-left">
                                Electronic Keyboard
                              </p>
                            </div>
                            <p
                              className="size-textxs text ui w-[96%] !font-poppins leading-[15px] md:w-full md:text-center text-left">
                              Learn to play your favourite song with our electronic keyboard course. Our professionals will walk you through international standard techniques while instilling confidence and skills. Chart the path to a game-changing journey now!
                            </p>
                          </div>
                          <Link to="https://wa.me/19453087641" target='_blank'
                            className="round fill size-xs teal_a400 button ui ml-3 h-[34px] min-w-[118px] !rounded !bg-teal-a400 px-2.5 font-poppins text-[12px] !text-light_blue-900 md:ml-0">
                            Explore Courses
                          </Link>
                        </div>
                      </div>
                      <div className="flex flex-col border-[0.75px] border-solid border-blue-100 bg-gray-100 md:w-full">
                        <img src={img_1} alt="Flute" className="h-[120px] object-cover" />
                        <div className="mb-3 flex flex-col items-start justify-between h-full md:items-center">
                          <div className="flex flex-col items-start gap-1.5 self-stretch px-3.5 py-[18px]">
                            <div className="flex self-stretch">
                              <p
                                className="size-textxl text ui w-[82%] md:w-full md:text-center !font-poppins leading-[21px] !text-light_blue-900 text-left">
                                Flute
                              </p>
                            </div>
                            <p
                              className="size-textxs text ui w-[96%] !font-poppins leading-[15px] md:w-full md:text-center text-left">
                              The flute was first introduced in international classical music then it became a popular learning instrument among enthusiasts. Our teachers will strengthen your foundation and prepare you for global certifications.
                            </p>
                          </div>
                          <Link to="https://wa.me/19453087641" target='_blank'
                            className="round fill size-xs teal_a400 button ui ml-3 h-[34px] min-w-[118px] !rounded !bg-teal-a400 px-2.5 font-poppins text-[12px] !text-light_blue-900 md:ml-0">
                            Explore Courses
                          </Link>
                        </div>
                      </div>
                      <div className="flex flex-col border-[0.75px] border-solid border-blue-100 bg-gray-100 md:w-full">
                        <img src={img_4} alt="Piano" className="h-[120px] object-cover" />
                        <div className="mb-3 flex flex-col items-start justify-between h-full md:items-center">
                          <div className="flex flex-col items-start gap-1.5 self-stretch px-3.5 py-[18px]">
                            <div className="flex self-stretch">
                              <p
                                className="size-textxl text ui w-[82%] md:w-full md:text-center !font-poppins leading-[21px] !text-light_blue-900 text-left">
                                Piano
                              </p>
                            </div>
                            <p
                              className="size-textxs text ui w-[96%] !font-poppins leading-[15px] md:w-full md:text-center text-left">
                                Your journey to becoming an aspiring piano player begins with us. From classical techniques to contemporary style, play your favourite songs with ease under our expert guidance. Transform your vision and become an aspiring piano enthusiast.
                            </p>
                          </div>
                          <Link to="https://wa.me/19453087641" target='_blank'
                            className="round fill size-xs teal_a400 button ui ml-3 h-[34px] min-w-[118px] !rounded !bg-teal-a400 px-2.5 font-poppins text-[12px] !text-light_blue-900 md:ml-0">
                            Explore Courses
                          </Link>
                        </div>
                      </div>
                      <div className="flex flex-col border-[0.75px] border-solid border-blue-100 bg-gray-100 md:w-full">
                        <img src={img_3} alt="Tabla" className="h-[120px] object-cover" />
                        <div className="mb-3 flex flex-col items-start justify-between h-full md:items-center">
                          <div className="flex flex-col items-start gap-1.5 self-stretch px-3.5 py-[18px]">
                            <div className="flex self-stretch">
                              <p
                                className="size-textxl text ui w-[82%] md:w-full md:text-center !font-poppins leading-[21px] !text-light_blue-900 text-left">
                                Tabla
                              </p>
                            </div>
                            <p
                              className="size-textxs text ui w-[96%] !font-poppins leading-[15px] md:w-full md:text-center text-left">
                                The table is known for its intricate rhythms along with expressive sounds played using a variety of techniques. Whether you are a beginner or an experienced professional, we offer a structured program to hone your tabla skills.
                            </p>
                          </div>
                          <Link to="https://wa.me/19453087641" target='_blank'
                            className="round fill size-xs teal_a400 button ui ml-3 h-[34px] min-w-[118px] !rounded !bg-teal-a400 px-2.5 font-poppins text-[12px] !text-light_blue-900 md:ml-0">
                            Explore Courses
                          </Link>
                        </div>
                      </div>
                      <div className="flex flex-col border-[0.75px] border-solid border-blue-100 bg-gray-100 md:w-full">
                        <img src={img_5} alt="Acoustic Guitar" className="h-[120px] object-cover" />
                        <div className="mb-3 flex flex-col items-start justify-between h-full md:items-center">
                          <div className="flex flex-col items-start gap-1.5 self-stretch px-3.5 py-[18px]">
                            <div className="flex self-stretch">
                              <p
                                className="size-textxl text ui w-[82%] md:w-full md:text-center !font-poppins leading-[21px] !text-light_blue-900 text-left">
                                Acoustic Guitar
                              </p>
                            </div>
                            <p
                              className="size-textxs text ui w-[96%] !font-poppins leading-[15px] md:w-full md:text-center text-left">
                              Whether your child wants to learn his favourite songs or is aspiring to become a rockstar, enroll him in our acoustic guitar course. Our certified & friendly instructors will take him through the guitar basics & will help them acquire international certifications.
                            </p>
                          </div>
                          <Link to="https://wa.me/19453087641" target='_blank'
                            className="round fill size-xs teal_a400 button ui ml-3 h-[34px] min-w-[118px] !rounded !bg-teal-a400 px-2.5 font-poppins text-[12px] !text-light_blue-900 md:ml-0">
                            Explore Courses
                          </Link>
                        </div>
                      </div>
                      <div className="flex flex-col border-[0.75px] border-solid border-blue-100 bg-gray-100 md:w-full">
                        <img src={img_6} alt="Violin" className="h-[120px] object-cover" />
                        <div className="mb-3 flex flex-col items-start justify-between h-full md:items-center">
                          <div className="flex flex-col items-start gap-1.5 self-stretch px-3.5 py-[18px]">
                            <div className="flex self-stretch">
                              <p
                                className="size-textxl text ui w-[82%] md:w-full md:text-center !font-poppins leading-[21px] !text-light_blue-900 text-left">
                                Violin
                              </p>
                            </div>
                            <p
                              className="size-textxs text ui w-[96%] !font-poppins leading-[15px] md:w-full md:text-center text-left">
                              Your child could be the next Beethoven as we help him navigate the chords of the exceptional Violin. During the violin classes, you will be trained on traditional & contemporary songs to become an all-around musician.
                            </p>
                          </div>
                          <Link to="https://wa.me/19453087641" target='_blank'
                            className="round fill size-xs teal_a400 button ui ml-3 h-[34px] min-w-[118px] !rounded !bg-teal-a400 px-2.5 font-poppins text-[12px] !text-light_blue-900 md:ml-0">
                            Explore Courses
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="transition-all duration-300 tab-2">
                    <div className="w-[100%] grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-4">
                      <div className="flex flex-col border-[0.75px] border-solid border-blue-100 bg-gray-100 md:w-full">
                        <img src={hindustani} alt="Hindustani Vocals" className="h-[120px] object-cover" />
                        <div className="mb-3 flex flex-col items-start md:items-center justify-between h-full">
                          <div
                            className="flex flex-col items-start gap-1.5 self-stretch px-3.5 py-[18px] h-full">
                            <div className="flex self-stretch">
                              <p
                                className="size-textxl text ui w-[82%] md:w-full md:text-center !font-poppins leading-[21px] !text-light_blue-900 text-left">
                                  Hindustani Vocals
                              </p>
                            </div>
                            <p
                              className="size-textxs text ui w-[96%] !font-poppins leading-[15px] md:w-full md:text-center text-left">
                              In this performance-based course, you will learn voice culture, various ragas, and talas while exploring the versatility of Indian culture. Our friendly and certified teachers will also prepare you for the Akhil Bharatiya Gandharva Mahavidyalaya certification.
                            </p>
                          </div>
                          <Link to="https://wa.me/19453087641" target='_blank'
                            className="round fill size-xs teal_a400 button ui ml-3 h-[34px] min-w-[118px] !rounded !bg-teal-a400 px-2.5 font-poppins text-[12px] !text-light_blue-900 md:ml-0">
                            Explore Courses
                          </Link>
                        </div>
                      </div>
                      <div className="flex flex-col border-[0.75px] border-solid border-blue-100 bg-gray-100 md:w-full">
                        <img src={western} alt="Western Vocals" className="h-[120px] object-cover" />
                        <div className="mb-3 flex flex-col items-start justify-between h-full md:items-center">
                          <div className="flex flex-col items-start gap-1.5 self-stretch px-3.5 py-[18px]">
                            <div className="flex self-stretch">
                              <p
                                className="size-textxl text ui w-[82%] md:w-full md:text-center !font-poppins leading-[21px] !text-light_blue-900 text-left">
                                Western Vocals
                              </p>
                            </div>
                            <p
                              className="size-textxs text ui w-[96%] !font-poppins leading-[15px] md:w-full md:text-center text-left">
                              Experience international music through this performance-based course. It will introduce you to various global musical genres, such as Blues, Jazz, Pop, and Rock. Our expert instructors will also assist you in preparing for international certifications from Trinity, RSL, or ABRSM.
                            </p>
                          </div>
                          <Link to="https://wa.me/19453087641" target='_blank'
                            className="round fill size-xs teal_a400 button ui ml-3 h-[34px] min-w-[118px] !rounded !bg-teal-a400 px-2.5 font-poppins text-[12px] !text-light_blue-900 md:ml-0">
                            Explore Courses
                          </Link>
                        </div>
                      </div>
                      <div className="flex flex-col border-[0.75px] border-solid border-blue-100 bg-gray-100 md:w-full">
                        <img src={carnatic} alt="Carnatic Vocals" className="h-[120px] object-cover" />
                        <div className="mb-3 flex flex-col items-start justify-between h-full md:items-center">
                          <div className="flex flex-col items-start gap-1.5 self-stretch px-3.5 py-[18px]">
                            <div className="flex self-stretch">
                              <p
                                className="size-textxl text ui w-[82%] md:w-full md:text-center !font-poppins leading-[21px] !text-light_blue-900 text-left">
                                Carnatic Vocals
                              </p>
                            </div>
                            <p
                              className="size-textxs text ui w-[96%] !font-poppins leading-[15px] md:w-full md:text-center text-left">
                              A comprehensive Carnatic music training program that is deeply rooted in South Indian language, culture, and tradition. This course guides you from Sarali Varisai to keerthanams and geethams, helping you explore the rich, centuries-old musical legacy of Carnatic music.
                            </p>
                          </div>
                          <Link to="https://wa.me/19453087641" target='_blank'
                            className="round fill size-xs teal_a400 button ui ml-3 h-[34px] min-w-[118px] !rounded !bg-teal-a400 px-2.5 font-poppins text-[12px] !text-light_blue-900 md:ml-0">
                            Explore Courses
                          </Link>
                        </div>
                      </div>
                      <div className="flex flex-col border-[0.75px] border-solid border-blue-100 bg-gray-100 md:w-full">
                        <img src={bollywood} alt="Bollywood Vocals" className="h-[120px] object-cover" />
                        <div className="mb-3 flex flex-col items-start justify-between h-full md:items-center">
                          <div className="flex flex-col items-start gap-1.5 self-stretch px-3.5 py-[18px]">
                            <div className="flex self-stretch">
                              <p
                                className="size-textxl text ui w-[82%] md:w-full md:text-center !font-poppins leading-[21px] !text-light_blue-900 text-left">
                                Bollywood Vocals
                              </p>
                            </div>
                            <p
                              className="size-textxs text ui w-[96%] !font-poppins leading-[15px] md:w-full md:text-center text-left">
                              If you're interested in singing as a hobby or want to learn how to sing your favorite songs, this course is ideal for you. Our friendly instructors will assist you with vocal training and help you develop foundational skills in film music, enabling you to sing your favorite songs with confidence and ease.
                            </p>
                          </div>
                          <Link to="https://wa.me/19453087641" target='_blank'
                            className="round fill size-xs teal_a400 button ui ml-3 h-[34px] min-w-[118px] !rounded !bg-teal-a400 px-2.5 font-poppins text-[12px] !text-light_blue-900 md:ml-0">
                            Explore Courses
                          </Link>
                        </div>
                      </div>
                      <div className="flex flex-col border-[0.75px] border-solid border-blue-100 bg-gray-100 md:w-full">
                        <img src={telugu} alt="Telugu Film Music" className="h-[120px] object-cover" />
                        <div className="mb-3 flex flex-col items-start justify-between h-full md:items-center">
                          <div className="flex flex-col items-start gap-1.5 self-stretch px-3.5 py-[18px]">
                            <div className="flex self-stretch">
                              <p
                                className="size-textxl text ui w-[82%] md:w-full md:text-center !font-poppins leading-[21px] !text-light_blue-900 text-left">
                                Telugu Film Music
                              </p>
                            </div>
                            <p
                              className="size-textxs text ui w-[96%] !font-poppins leading-[15px] md:w-full md:text-center text-left">
                              Experience a fun and relaxing session singing your favorite Telugu songs or dive into music with passion. Whether you are aiming to refine your skills or simply enjoy singing, the Spardha Telugu Film course is the ideal entry point for fans of Telugu music. Our certified and professional teachers offer personalized one-on-one sessions to help you achieve your goals while having a great time.
                            </p>
                          </div>
                          <Link to="https://wa.me/19453087641" target='_blank'
                            className="round fill size-xs teal_a400 button ui ml-3 h-[34px] min-w-[118px] !rounded !bg-teal-a400 px-2.5 font-poppins text-[12px] !text-light_blue-900 md:ml-0">
                            Explore Courses
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="transition-all duration-300 tab-3">
                    <div className="w-[100%] grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-4">
                      <div className="flex flex-col border-[0.75px] border-solid border-blue-100 bg-gray-100 md:w-full">
                        <img src={img_2} alt="Electronic Keyboard" className="h-[120px] object-cover" />
                        <div className="mb-3 flex flex-col items-start md:items-center">
                          <div
                            className="flex flex-col items-start gap-1.5 self-stretch px-3.5 py-[18px] justify-between h-full">
                            <div className="flex self-stretch">
                              <p
                                className="size-textxl text ui w-[82%] md:w-full md:text-center !font-poppins leading-[21px] !text-light_blue-900 text-left">
                                Electronic Keyboard
                              </p>
                            </div>
                            <p
                              className="size-textxs text ui w-[96%] !font-poppins leading-[15px] md:w-full md:text-center text-left">
                              The keyboard's lightweight and portable nature makes it the best choice for children to
                              acquire
                              musical comprehension. We strengthen your child's basic and advanced skills effortlessly
                              on the
                              keyboard. Our experienced tutors will prepare your child for international certifications.
                            </p>
                          </div>
                          <Link to="https://wa.me/19453087641" target='_blank'
                            className="round fill size-xs teal_a400 button ui ml-3 h-[34px] min-w-[118px] !rounded !bg-teal-a400 px-2.5 font-poppins text-[12px] !text-light_blue-900 md:ml-0">
                            Explore Courses
                          </Link>
                        </div>
                      </div>
                      <div className="flex flex-col border-[0.75px] border-solid border-blue-100 bg-gray-100 md:w-full">
                        <img src={img_1} alt="Flute" className="h-[120px] object-cover" />
                        <div className="mb-3 flex flex-col items-start justify-between h-full md:items-center">
                          <div className="flex flex-col items-start gap-1.5 self-stretch px-3.5 py-[18px]">
                            <div className="flex self-stretch">
                              <p
                                className="size-textxl text ui w-[82%] md:w-full md:text-center !font-poppins leading-[21px] !text-light_blue-900 text-left">
                                Flute
                              </p>
                            </div>
                            <p
                              className="size-textxs text ui w-[96%] !font-poppins leading-[15px] md:w-full md:text-center text-left">
                              Flute originated from Hindustani classNameical music. Learning flute offers detailed insight
                              into
                              Indian culture while allowing you to hone your musical skills. Our experts will strengthen
                              your
                              foundation while preparing you for international events.
                            </p>
                          </div>
                          <Link to="https://wa.me/19453087641" target='_blank'
                            className="round fill size-xs teal_a400 button ui ml-3 h-[34px] min-w-[118px] !rounded !bg-teal-a400 px-2.5 font-poppins text-[12px] !text-light_blue-900 md:ml-0">
                            Explore Courses
                          </Link>
                        </div>
                      </div>
                      <div className="flex flex-col border-[0.75px] border-solid border-blue-100 bg-gray-100 md:w-full">
                        <img src={img_4} alt="Piano" className="h-[120px] object-cover" />
                        <div className="mb-3 flex flex-col items-start justify-between h-full md:items-center">
                          <div className="flex flex-col items-start gap-1.5 self-stretch px-3.5 py-[18px]">
                            <div className="flex self-stretch">
                              <p
                                className="size-textxl text ui w-[82%] md:w-full md:text-center !font-poppins leading-[21px] !text-light_blue-900 text-left">
                                Piano
                              </p>
                            </div>
                            <p
                              className="size-textxs text ui w-[96%] !font-poppins leading-[15px] md:w-full md:text-center text-left">
                              From classNameical to pop names, we train your child on these songs. The unrivalled support of
                              our
                              experts helps your child acquire international certifications. Join us now to watch your
                              child
                              transform into a confident piano artist.
                            </p>
                          </div>
                          <Link to="https://wa.me/19453087641" target='_blank'
                            className="round fill size-xs teal_a400 button ui ml-3 h-[34px] min-w-[118px] !rounded !bg-teal-a400 px-2.5 font-poppins text-[12px] !text-light_blue-900 md:ml-0">
                            Explore Courses
                          </Link>
                        </div>
                      </div>
                      <div className="flex flex-col border-[0.75px] border-solid border-blue-100 bg-gray-100 md:w-full">
                        <img src={img_3} alt="Tabla" className="h-[120px] object-cover" />
                        <div className="mb-3 flex flex-col items-start justify-between h-full md:items-center">
                          <div className="flex flex-col items-start gap-1.5 self-stretch px-3.5 py-[18px]">
                            <div className="flex self-stretch">
                              <p
                                className="size-textxl text ui w-[82%] md:w-full md:text-center !font-poppins leading-[21px] !text-light_blue-900 text-left">
                                Tabla
                              </p>
                            </div>
                            <p
                              className="size-textxs text ui w-[96%] !font-poppins leading-[15px] md:w-full md:text-center text-left">
                              Tabla is the heart of Indian classNameical music. Enhance your tabla skills to navigate the
                              versatility offered by Indian heritage and classNameical music. With us, you develop a strong
                              tala
                              foundation and present yourself at international levels.
                            </p>
                          </div>
                          <Link to="https://wa.me/19453087641" target='_blank'
                            className="round fill size-xs teal_a400 button ui ml-3 h-[34px] min-w-[118px] !rounded !bg-teal-a400 px-2.5 font-poppins text-[12px] !text-light_blue-900 md:ml-0">
                            Explore Courses
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <script src="https://cdn.jsdelivr.net/npm/alpinejs@2.8.2/dist/alpine.min.js" defer></script> */}
            </div>
          </div>
        </div>
      </div>

      {/* <call to action section */}
      <div className="bg-primary py-[102px] md:py-5 hidden">
        <div className="flex justify-center">
          <div className="container-xs flex justify-center md:px-5">
            <div className="flex w-full justify-center rounded-[18px] bg-light_blue-a700 px-14 py-[72px] md:p-5">
              <div className="flex w-[92%] flex-col items-center gap-[22px] md:w-full">
                <h2
                  className="size-heading4xl heading ui self-stretch text-center !font-nunito leading-[115%] !text-color_4 sm:text-[38px]">
                  Unlock Your Access to Industry Leaders – Register for Xelaras Now!
                </h2>
                <p className="size-text2xl text ui self-stretch text-center !font-asap leading-[150%] !text-primary">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet sodales magna. Nulla id
                  tortor ultricies, tincidunt nulla vel, aliquet risus. Duis ac justo sed leo fringilla bibendum.
                  Vestibulum pulvinar porttitor porta. Nulla facilisi.
                </p>
                <div className="flex gap-2.5">
                  <button
                    className="fill size-md primary button ui h-[38px] min-w-[148px] !rounded-[18px] !bg-primary px-6 font-nunito text-[13.5px] font-bold uppercase tracking-[0.68px] !text-black-900_01 sm:px-5">
                    Get a Ticket
                  </button>
                  <button
                    className="fill size-md gray_100_3f button ui h-[38px] min-w-[148px] !rounded-[18px] !bg-gray-100_3f px-[26px] font-nunito text-[13.5px] font-bold uppercase tracking-[0.68px] !text-primary sm:px-5">
                    learn more
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Courses