import React from 'react'
import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';

function RootLayout() {
  return (
    <div className='w-full bg-primary'>
      <Header />
      <div className='all-component-wrap'>
        <Outlet />
      </div>
      <Footer />
    </div>
  )
}

export default RootLayout