import React, { useState } from 'react'
import img_sr2_1103_1 from '../public/images/img_sr2_1103_1.webp';
import img_linkedin_color_3 from '../public/images/img_linkedin_color_3.svg';
import img_lock from '../public/images/img_lock.svg';
import img_call from '../public/images/img_call.svg';
import axios from 'axios';
import { Link } from 'react-router-dom';

function ContactUs() {

    const [showMsg, setShowMsg] = useState(false)

    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        message: '',
    })

    const [error, setError] = useState({})

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData, [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const validationErrors = {}
        if (!formData.firstname.trim()) {
            validationErrors.firstname = "Username is required"
        }
        if (!formData.lastname.trim()) {
            validationErrors.lastname = "Lastname is required"
        }
        if (!formData.email.trim()) {
            validationErrors.email = "Email is required"
        }
        // else if (/\S+@\S+\.\S+/.test(formData.email)) {
        //     validationErrors.email = "Email is not valid"
        // }
        if (!formData.phone.trim()) {
            validationErrors.phone = "Phone number is required"
        } else if (formData.phone.length < 10) {
            validationErrors.phone = "Enter 10 digit phone number"
        } else if (/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])S+/.test(formData.phone)) {
            validationErrors.phone = "Enter number in digits"
        } else if (formData.phone.length > 10) {
            validationErrors.phone = "Phone number should not be more than 10 characters"
        }
        if (!formData.message.trim()) {
            validationErrors.message = "Message is required"
        } else if (formData.message.length < 10) {
            validationErrors.message = "Message should be more than 10 characters"
        }
        formData.send_enquiry = true
        setError(validationErrors)
        if (Object.keys(validationErrors).length === 0) {

            setShowMsg(true)
            setTimeout(() => {
                window.location.reload();
              }, 4000);

            async function getData(url, data) {
                const res = await axios({
                    // Endpoint to send files
                    url: url,
                    method: "POST",
                    data: data,
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8'
                    }
                });
                return await res.data;
            }
            const res = getData('https://www.mufinacademy.com/mufinphp/action.php', formData)
            console.log(res);
        }
    }
    
    setTimeout(() => {
      setShowMsg(false)
    }, 22000);

    return (
        <div>
            {/* banner section */}
            <div>
                <div className="border-b border-solid border-color_1 bg-color_3">
                    <div
                        className="contact-banner flex h-[404px] items-start justify-center bg-cover bg-no-repeat py-[60px] md:h-auto md:py-5">
                        <div className="container-xs mb-4 flex justify-center md:px-5">
                            <div className="w-full">
                                <div className="flex items-start justify-between gap-5 md:flex-col">
                                    <img src={img_sr2_1103_1} alt="Contact us banner"
                                        className="h-[266px] w-[42%] self-center rounded-[18px] object-contain md:w-full" />
                                    <div className="mt-[74px] md:mt-[0] flex w-[48%] flex-col items-start md:items-center md:w-full">
                                        <h1 className="size-heading5xl heading ui !font-nunito !text-secondary-0 sm:text-[40px]">
                                            <span className="text-color_4">Contact&nbsp;</span>
                                            <span className="text-secondary-0">Us</span>
                                        </h1>
                                        <div className="flex flex-wrap">
                                            <Link to="/"
                                                className="size-text2xl text ui !font-poppins !font-medium !text-color_4 mr-[0.5px]">Home -</Link>
                                            <Link to="/contactus"
                                                className="size-text2xl text ui !font-poppins !font-medium !text-secondary-0">Contact</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col items-center gap-[84px] bg-color_3 md:gap-[63px] sm:gap-[42px]">
                {/* contact form section */}
                <div className="mt-[66px] flex justify-center self-stretch">
                    <div className="container-xs flex justify-center md:px-5">
                        <div
                            className="flex w-full flex-col items-center gap-2 rounded-[18px] border-b-2 border-solid border-secondary-0 bg-color_4 p-10 shadow-xs sm:p-5">
                            <h2 className="size-heading4xl heading ui !font-nunito !text-color_2 sm:text-[38px]">Send Us a Message</h2>
                            <div className="mb-3.5 mt-3 flex w-[90%] justify-center md:w-full">
                                <div className="flex w-full flex-col items-start">
                                    <form action="" className='w-full' onSubmit={handleSubmit}>
                                        <div className="flex items-center gap-[34px] md:gap-[20px] self-stretch md:flex-col">
                                            <div className="flex w-full flex-col gap-3">
                                                <div className="flex flex-col items-start">
                                                    <h3 className="text-[14px] heading ui !font-nunito font-bold !text-color_2 mb-2">
                                                        First Name
                                                    </h3>
                                                    <label
                                                        className="round fill size-xs gray_400_59 input ui h-[34px] self-stretch !rounded-[16px] !bg-gray-400_59 px-[18px] font-asap text-[14px] !text-color_5">
                                                        <input name="firstname" placeholder="First Name" type="text" onChange={handleChange} />
                                                    </label>
                                                    <p className='text-[12px] text-red-500 text-left relative top-1'>{error.firstname && <span>{error.firstname}</span>}</p>
                                                </div>
                                                <div className="flex flex-col items-start">
                                                    <h4 className="text-[14px] heading ui !font-nunito font-bold !text-color_2 mb-2">
                                                        Phone Number
                                                    </h4>
                                                    <label
                                                        className="round fill size-xs gray_400_59 input ui h-[34px] self-stretch !rounded-[16px] !bg-gray-400_59 px-[18px] font-asap text-[14px] !text-color_5">
                                                        <input name="phone" placeholder="Phone Number" type="number" onChange={handleChange} />
                                                    </label>
                                                    <p className='text-[12px] text-red-500 text-left relative top-1'>{error.phone && <span>{error.phone}</span>}</p>
                                                </div>
                                            </div>
                                            <div className="flex w-full flex-col gap-3">
                                                <div className="flex flex-col items-start">
                                                    <h5 className="text-[14px] heading ui !font-nunito font-bold !text-color_2 mb-2">Last Name</h5>
                                                    <label
                                                        className="round fill size-xs gray_400_59 input ui h-[34px] self-stretch !rounded-[16px] !bg-gray-400_59 px-[18px] font-asap text-[14px] !text-color_5">
                                                        <input name="lastname" placeholder="Last Name" type="text" onChange={handleChange} />
                                                    </label>
                                                    <p className='text-[12px] text-red-500 text-left relative top-1'>{error.lastname && <span>{error.lastname}</span>}</p>
                                                </div>
                                                <div className="flex flex-col items-start">
                                                    <h6 className="text-[14px] heading ui !font-nunito font-bold !text-color_2 mb-2">E-mail</h6>
                                                    <label
                                                        className="round fill size-xs gray_400_59 input ui h-[34px] self-stretch !rounded-[16px] !bg-gray-400_59 px-[18px] font-asap text-[14px] !text-color_5">
                                                        <input name="email" placeholder="E-mail" type="email" onChange={handleChange} />
                                                    </label>
                                                    <p className='text-[12px] text-red-500 text-left relative top-1'>{error.email && <span>{error.email}</span>}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-area w-full">
                                            <h6 className="size-headingmd heading ui mt-3 !font-nunito font-medium !text-color_2 mb-2 text-left">Message</h6>
                                            <textarea
                                                className="round tarfillgray40059 size-xs gray_400_59 textarea ui h-[134px] self-stretch !rounded-[18px] !bg-gray-400_59 px-[18px] py-3 font-asap text-[14px] text-color_5 w-full"
                                                name="message" placeholder="Message" onChange={handleChange}></textarea>
                                            <p className='text-[12px] text-red-500 text-left relative top-1'>{error.message && <span>{error.message}</span>}</p>
                                        </div>
                                        <button
                                            className="fill size-md teal_a400 button ui mt-[18px] h-[38px] min-w-[162px] !rounded-[18px] !bg-teal-a400 px-[22px] font-nunito text-[14px] font-bold uppercase tracking-[0.70px] !text-color_2 sm:px-5">
                                            send message
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        showMsg &&
                        <div className="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md fixed top-5 right-5 w-[300px] rounded-[5px]" role="alert">
                        <div className="flex flex-col justify-center items-center">
                            <p className="font-bold text-[14px]">Thank You!</p>
                            <p className="mt-1 text-[14px]">We will be in touch with you.</p>
                        </div>
                        </div>
                    }
                </div>
                <div className="container-xs md:px-5">
                    <div className="flex gap-[22px] md:flex-col">
                        <div
                            className="flex w-[32%] flex-col items-center gap-3 rounded-[18px] border-b-2 border-solid border-secondary-0 bg-color_4 p-[30px] shadow-xs md:w-full sm:p-5">
                            <button
                                className="circle fill size-3xl secondary_0 button ui h-[64px] w-[64px] !rounded-[32px] !bg-secondary-0 px-3.5">
                                <img src={img_linkedin_color_3} alt='12-11-208, Warasiguda, Secunderabad, Telangana, India' />
                            </button>
                            <h6 className="size-headinglg heading ui mb-2 text-center !font-nunito leading-6 !text-color_2">
                                12-11-208, Warasiguda,<br />Secunderabad, Telangana, India
                            </h6>
                        </div>
                        <div
                            className="flex w-[32%] flex-col items-center gap-3 rounded-[18px] border-b-2 border-solid border-secondary-0 bg-color_4 p-[30px] shadow-xs md:w-full sm:p-5">
                            <button
                                className="circle fill size-3xl secondary_0 button ui h-[64px] w-[64px] !rounded-[32px] !bg-secondary-0 px-3.5">
                                <img src={img_lock} alt='connect@mufinacademy.com' />
                            </button>
                            <Link to='mailto:connect@mufinacademy.com' className="size-headinglg heading ui mb-2 text-center !font-nunito leading-6 !text-color_2">
                                connect@mufinacademy.com
                            </Link>
                        </div>
                        <div
                            className="flex w-[32%] flex-col items-center gap-3 rounded-[18px] border-b-2 border-solid border-secondary-0 bg-color_4 p-[30px] shadow-xs md:w-full sm:p-5">
                            <button
                                className="circle fill size-3xl secondary_0 button ui h-[64px] w-[64px] !rounded-[32px] !bg-secondary-0 px-3.5">
                                <img src={img_call} alt="+91 89197 97982" />
                            </button>
                            <h6 className="size-headinglg heading ui mb-2 text-center !font-nunito leading-6 !text-color_2">
                                +91 89197 97982
                            </h6>
                        </div>
                    </div>
                </div>
                <iframe title='Mufin' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.8325474857984!2d78.51225567684386!3d17.419821959280675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb998f656ad8b7%3A0x26c09d2f27b235d!2s12-11-1292%2C%20Warasiguda%2C%20Boudhanagar%20Colony%2C%20Hyderabad%2C%20Secunderabad%2C%20Telangana%20500061!5e0!3m2!1sen!2sin!4v1733662570035!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className="h-[376px] self-stretch border-[0px]"></iframe>
            </div>
        </div>
    )
}

export default ContactUs