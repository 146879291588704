import React from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import $ from 'jquery';
import "jquery-ui-dist/jquery-ui";
import img_logv6_1 from '../public/images/img_logv6_1.png';

function Header() {

    let location = useLocation();

    useEffect(() => {
    }, [location])

    const hideMobileNav = () => {
        $('body').removeClass('active');
        $('.mobile-menu').removeClass('active');
    }

    return (
        <header
            className="relative flex items-center justify-between gap-5 bg-color_3 px-[116px] md:px-5 bsm:flex-row bsm:justify-between">
                <Link to="/" className='w-[12%] bsm:w-[100px] h-[80px] inline-flex'>
                    <img src={img_logv6_1} alt="Mufin"
                        className="ml-3.5 w-full object-contain md:ml-0" />
                </Link>
            <Link className="hamburger-btn asm:hidden inline-block w-[20px]" to="#">
                <svg viewBox="0 0 100 80">
                    <rect width="100" height="20" rx="10"></rect>
                    <rect y="30" width="100" height="20" rx="10"></rect>
                    <rect y="60" width="100" height="20" rx="10"></rect>
                </svg>
            </Link>
            <div className="flex items-center gap-[42px] bsm:hidden bsm:gap-[20px] bsm:flex-col mobile-menu">
                <Link to="#" className="close-menu-btn asm:hidden">
                    <svg viewPort="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <line x1="1" y1="15" x2="15" y2="1" stroke="black" stroke-width="2" />
                        <line x1="1" y1="1" x2="15" y2="15" stroke="black" stroke-width="2" />
                    </svg>
                </Link>
                <ul className="flex gap-9 sm:flex-col bsm:gap-8 bsm:flex-col bsm:items-center">
                    <li>
                        <Link to="/" className={`nav-link ${location.pathname === "/" ? "active" : ""}`} onClick={hideMobileNav}>
                            <p className="size-textlg heading ui !text-color_2">Home</p>
                        </Link>
                    </li>
                    <li>
                        <Link to="/aboutus" className={`nav-link ${location.pathname === "/aboutus" ? "active" : ""}`} onClick={hideMobileNav}>
                            <p className="size-textlg heading ui !text-color_2">About Us</p>
                        </Link>
                    </li>
                    <li>
                        <Link to="/courses" className={`nav-link ${location.pathname === "/courses" ? "active" : ""}`} onClick={hideMobileNav}>
                            <p className="size-textlg heading ui !text-color_2">Courses</p>
                        </Link>
                    </li>
                    <li>
                        <Link to="/contactus" className={`nav-link ${location.pathname === "/contactus" ? "active" : ""}`} onClick={hideMobileNav}>
                            <p className="size-textlg heading ui !text-color_2">Contact</p>
                        </Link>
                    </li>
                    <li>
                        <Link onClick={hideMobileNav} className='hidden'>
                            <p className="size-textlg heading ui !text-color_2">Login</p>
                        </Link>
                    </li>
                </ul>
                <Link to="https://wa.me/19453087641" target='_blank'
                    className="fill size-md teal_a400 button ui h-[38px] min-w-[162px] !rounded-[18px] !bg-teal-a400 px-5 font-asap text-[14px] !text-black-900" onClick={hideMobileNav}>
                    BOOK A FREE TRAIL
                </Link>
            </div>
        </header>
    )
}

export default Header