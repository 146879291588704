import React from 'react'
import { Link } from 'react-router-dom';
import img_checkmark from '../public/images/img_checkmark.svg';
import img_8_1 from '../public/images/img_8_1.webp';
import img_img1_1 from '../public/images/img_img1_1.webp';
import img_close from '../public/images/img_close.svg';
import img_close_teal_a400 from '../public/images/img_close_teal_a400.svg';
import img_2 from '../public/images/img_2.webp';
import img_1 from '../public/images/img_1.webp';
import img_4 from '../public/images/img_4.webp';
import img_3 from '../public/images/img_3.webp';
import prashanth from '../public/images/Prashanth.webp';
import vani from '../public/images/Vani_Sharma.webp';
import manju from '../public/images/Manju.webp';
import testimonial_img from '../public/images/testimonial_female.png';
import NewsLetter from './NewsLetter';
// import img_arrow_left from '../public/images/img_arrow_left.svg';
// import img_arrow_right from '../public/images/img_arrow_right.svg';
// swiper slider
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

function Home() {

  const scroll = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  return (
    <div>
      {/* hero section */}
      <div className="h-[684px] md:h-auto">
        <div
          className="home-banner flex h-[684px] items-center justify-center bg-cover bg-no-repeat px-14 py-[180px] md:h-auto md:p-5 md:py-10">
          <div className="mt-1.5 flex w-[88%] flex-col items-start gap-8 md:w-full">
            <h1 className="size-heading4xl heading ui w-[56%] !font-poppins leading-[115%] text-primary md:w-full text-left">
              <span className="text-primary">Unlock your full potential with the World's Leading Academy today!</span>
              {/* <span className="text-color_4"> Unleash</span>
              <span className="text-light_blue-a700"> &nbsp;</span>
              <span className="text-primary"> Innovation</span>
              <span className="text-color_4"> , Ignite&nbsp;</span>
              <span className="text-primary"> Collaboration</span> */}
            </h1>
            <h2
              className="size-text3xl heading ui w-[56%] !font-poppins !font-normal leading-[150%] !text-color_4 md:w-full text-left">
              At Mufin Academy, each student has a distinct voice that needs to be explored by our professionals. As the
              world's leading academy, we nurture your talent to maximize its full potential and provide you with the
              tools and knowledge you need to thrive in the music realm.
            </h2>
            <Link to="https://wa.me/19453087641" target='_blank'
              className="fill size-xl primary button ui h-[46px] min-w-[244px] !rounded-[22px] !bg-primary px-5 font-poppins text-[16px] font-bold uppercase tracking-[0.80px] !text-black-900_01">
              Book a Free Demo
            </Link>
          </div>
        </div>
      </div>

      {/* features section */}
      <div>
        <div className="relative h-[606px] content-end bg-color_3 py-12 md:h-auto md:py-5 md:pt-10">
          <div className="mb-[126px] ml-auto h-[66px] w-[66px] rounded-[32px] bg-secondary-0 hidden"></div>
          <div
            className="container-xs absolute bottom-[8%] left-0 right-0 flex items-center justify-center gap-12 md:relative md:flex-col md:px-5">
            <div className="flex w-full flex-col items-start gap-[18px] md:items-center">
              <h2 className="size-headinglg heading ui !font-poppins !text-teal-a400">Only at Mufin!</h2>
              <div className="flex flex-col items-start gap-6 self-stretch md:items-center">
                <div className="flex flex-col gap-[18px] self-stretch md:items-center">
                  <h3 className="size-heading4xl heading ui !font-poppins leading-[115%] text-left !text-gray-900 sm:text-[38px]">
                    Igniting Innovation, Expanding Horizons
                  </h3>
                  <h4 className="size-textlg heading ui w-[86%] !font-asap !font-normal leading-[150%] md:w-full md:text-center">
                    Enhance your music & dance skills at your pace with our personalized & adaptive models streamlined
                    with your objectives. Get a hands-on, result-driven curriculum crafted by industry experts to speed
                    up your learning process. Explore the multiple performance avenues tailored to empower & hone your
                    technical skillsets. Join us to take advantage of:
                  </h4>
                  <div className="flex flex-col gap-1.5">
                    <div className="flex items-center gap-[15px]">
                      <img src={img_checkmark} alt="Checkmark Icon" className="h-[12px]" />
                      <h5 className="size-textlg heading ui !font-asap !font-normal !text-color_2">
                        Live sessions
                      </h5>
                    </div>
                    <div className="flex items-center gap-[15px]">
                      <img src={img_checkmark} alt="Checkmark Icon" className="h-[12px]" />
                      <h6 className="size-textlg heading ui !font-asap !font-normal !text-color_2">
                        Internationally Recognized Certifications
                      </h6>
                    </div>
                    <div className="flex items-center gap-[15px]">
                      <img src={img_checkmark} alt="Checkmark Icon" className="h-[12px]" />
                      <p className="size-textlg heading ui !font-asap !font-normal !text-color_2">
                        Learning Made Engaging & Fun
                      </p>
                    </div>
                    <div className="flex items-center gap-[15px]">
                      <img src={img_checkmark} alt="Checkmark Icon" className="h-[12px]" />
                      <p className="size-textlg heading ui !font-asap !font-normal !text-color_2">Training Offered by
                        Certified Professionals</p>
                    </div>
                  </div>
                </div>
                <Link onClick={scroll}
                  className="fill size-md secondary_0 button ui h-[38px] min-w-[148px] !rounded-[18px] !bg-secondary-0 px-[26px] font-nunito text-[13.5px] font-bold uppercase tracking-[0.68px] !text-color_3 sm:px-5" to="/aboutus">
                  kNOW more
                </Link>
              </div>
            </div>
            <div className="relative mb-3 h-[458px] w-full">
              <img src={img_8_1} alt="Kids singing"
                className="absolute right-[0.50px] top-0 m-auto h-[302px] w-[70%] rounded-[18px] object-contain" />
              <img src={img_img1_1} alt="Girl dancing"
                className="absolute bottom-[-0.25px] left-0 m-auto h-[300px] w-[68%] rounded-[18px] object-contain" />
              <div
                className="absolute bottom-0 left-0 right-0 top-0 m-auto flex h-max flex-1 flex-col items-start gap-[230px] md:gap-[172px] sm:gap-[115px]">
                <button
                  className="fill size-2xl color_4 button ui h-[56px] min-w-[210px] gap-2.5 !rounded-[10px] !bg-color_4 px-4 font-nunito text-[15px] font-semibold !text-color_2 !shadow-xs">
                  <img src={img_close} alt="Tailored Experience" className="h-[32px] w-[32px] object-contain" />
                  <span> Tailored Experience</span>
                </button>
                <button
                  className="fill size-2xl color_4 button ui h-[56px] min-w-[182px] gap-2.5 self-end !rounded-[10px] !bg-color_4 pl-3.5 pr-2.5 font-nunito text-[15px] font-semibold !text-color_2 !shadow-xs">
                  <img src={img_close_teal_a400} alt="Stage Performer"
                    className="h-[32px] w-[32px] object-contain" />
                  <span> Stage Performer</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* instructors section */}
      <div className="bg-color_3 py-[70px] md:py-5 md:pt-10">
        <div className="mb-9 flex flex-col items-center">
          <div className="container-xs flex flex-col gap-[34px] md:px-5">
            <div className="mx-[170px] flex flex-col items-center md:mx-0">
              <h2 className="size-headinglg heading ui !font-poppins !text-teal-a400">Meet Our Experienced Instructors</h2>
              <h3 className="size-heading4xl heading ui !font-poppins !text-color_2 sm:text-[38px]">
                Experienced and Recognized
              </h3>
              <p className="size-text2xl text ui mt-1 self-stretch text-center !font-asap leading-[150%] !text-gray-600_fc">
                We take pride in our experienced instructors that have been paving the way to success. Every instructor
                brings knowledge and expertise needed by every student to thrive in the competitive domain.
              </p>
            </div>
            <div className="flex gap-[22px] md:flex-col">
              <div className="flex w-[32%] flex-col md:w-full">
                <div>
                  <div className="h-[396px] rounded-[18px] bg-color_1 overflow-hidden">
                    <img src={prashanth} alt="Prashanth Kumar Kandikonda" className='w-full object-cover' />
                  </div>
                  <div
                    className="relative mx-[18px] mt-[-42px] flex flex-col items-center justify-center rounded-[10px] border-b-2 border-solid border-light_blue-a700 bg-color_4 p-3.5 shadow-xs md:mx-0">
                    <h4 className="size-headinglg heading ui !font-nunito !text-light_blue-a700">
                        Prashanth Kumar Kandikonda
                    </h4>
                    <h5 className="size-headingxs heading ui !font-nunito">Sound Engineer, 8 Years Experience</h5>
                  </div>
                </div>
              </div>
              <div className="flex w-[32%] flex-col md:w-full">
                <div>
                  <div className="h-[396px] rounded-[18px] bg-color_1 overflow-hidden">
                    <img src={vani} alt="Krishnam Shiva Vani Sharma" className='w-full object-cover' />
                  </div>
                  <div
                    className="relative mx-[18px] mt-[-42px] flex flex-col items-center justify-center rounded-[10px] border-b-2 border-solid border-light_blue-a700 bg-color_4 p-3.5 shadow-xs md:mx-0">
                    <h6 className="size-headinglg heading ui !font-nunito !text-light_blue-a700">
                        Krishnam Shiva Vani Sharma
                    </h6>
                    <p className="size-headingxs heading ui !font-nunito">Teacher, 8 Years Experience</p>
                  </div>
                </div>
              </div>
              <div className="flex w-[32%] flex-col md:w-full">
                <div>
                  <div className="h-[396px] rounded-[18px] bg-color_1 overflow-hidden">
                    <img src={manju} alt="Manju Latha Mandapakala" className='w-full object-cover' />
                  </div>
                  <div
                    className="relative mx-[18px] mt-[-42px] flex flex-col items-center justify-center rounded-[10px] border-b-2 border-solid border-light_blue-a700 bg-color_4 p-3.5 shadow-xs md:mx-0">
                    <h6 className="size-headinglg heading ui !font-nunito !text-light_blue-a700">Manju Latha Mandapakala</h6>
                    <p className="size-headingxs heading ui !font-nunito">Founder and Teacher, 16 Years Experience</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* call to action section */}
      <div className="what-makes-us">
        <div className="flex justify-center bg-color_3">
          <div className="container-xs flex justify-center md:px-5">
            <div
              className="flex w-full flex-col items-center justify-end gap-[30px] rounded-[18px] bg-light_blue-a700 px-14 py-[68px] md:p-5 sm:gap-[27px]">
              <h2
                className="size-heading4xl heading ui self-stretch text-center !font-poppins leading-[115%] !text-color_4 sm:text-[38px]">
                What Makes Us Different?
              </h2>
              <p className="size-textxl text ui self-stretch text-center !font-poppins leading-[150%] !text-color_3">
                We understand that choosing the right music academy is a significant step towards success. Below are the
                compelling reasons that make us the preferred choice of music aspirants worldwide:
              </p>
              <ul className="list-decimal grid bmd:grid-cols-1 amd:grid-cols-2 gap-4 pl-20c text-left">
                <li className="mb-2">
                  <h6 className="ui heading size-textlg !text-color_4 mb-2 inline-block pb-1">Courses Catered to Diverse
                    Levels</h6>
                  <p className="size-texts text ui self-stretch !font-poppins leading-[150%] !text-color_3">We offer
                    diversified courses developed considering the unique skill levels and interests in mind. Our experts
                    deliver a meticulously crafted curriculum to offer a well-rounded education.</p>
                </li>
                <li className="mb-2">
                  <h6 className="ui heading size-textlg !text-color_4 mb-2 inline-block pb-1">Modern Amenities</h6>
                  <p className="size-texts text ui self-stretch !font-poppins leading-[150%] !text-color_3">These boost
                    creativity and allow students to unveil their capabilities within professional settings.</p>
                </li>
                <li className="mb-2">
                  <h6 className="ui heading size-textlg !text-color_4 mb-2 inline-block pb-1">Performance Avenues</h6>
                  <p className="size-texts text ui self-stretch !font-poppins leading-[150%] !text-color_3">We offer
                    multiple prospects to students to demonstrate their talents, including recitals, competitions, and
                    events. After every performance, our professionals give constructive feedback while exposing them to
                    real-life scenarios.</p>
                </li>
                <li className="mb-2">
                  <h6 className="ui heading size-textlg !text-color_4 mb-2 inline-block pb-1">Proven Track Record</h6>
                  <p className="size-texts text ui self-stretch !font-poppins leading-[150%] !text-color_3">As a prominent
                    academy, we have established a strong foothold within the industry. We help our students at every
                    step of the journey until they accomplish their goals.</p>
                </li>
                <li className="mb-2">
                  <h6 className="ui heading size-textlg !text-color_4 mb-2 inline-block pb-1">Budget-Friendly Costing
                    Options</h6>
                  <p className="size-texts text ui self-stretch !font-poppins leading-[150%] !text-color_3">Discover our
                    reasonable pricing options, customized to your needs and vision. Enhance your experiences with our
                    budget-friendly rates without compromising on quality.</p>
                </li>
                <li>
                  <h6 className="ui heading size-textlg !text-color_4 mb-2 inline-block pb-1">Vibrant Community</h6>
                  <p className="size-texts text ui self-stretch !font-poppins leading-[150%] !text-color_3">Be a part of our
                    vibrant community that fosters collaboration and creativity necessary for individuals to thrive. You
                    make friendships that last while learning from those who push ahead.</p>
                </li>
              </ul>
              <Link to="https://wa.me/19453087641" target='_blank'
                className="fill size-xl primary button ui h-[44px] min-w-[168px] !rounded-[22px] !bg-primary px-8 font-nunito text-[14px] font-bold uppercase tracking-[0.70px] !text-black-900_01 sm:px-5">
                ENROLL NOW
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* courses section */}
      <div>
        <div className="flex flex-col items-center bg-color_3 py-[46px] md:py-10">
          <div className="container-xs flex flex-col items-center gap-[42px] px-14 md:px-5">
            <h2 className="size-heading4xl heading ui !font-poppins !font-semibold !text-light_blue-a700 sm:text-[38px]">
              Spotlight Our Distinguished Courses
            </h2>
            <div className="flex w-[96%] gap-[18px] md:w-full md:flex-col">
              <div className="flex w-[24%] flex-col border-[0.75px] border-solid border-blue-100 bg-gray-100 md:w-full">
                <img src={img_2} alt="Electronic Keyboard" className="h-[120px] object-cover" />
                <div className="mb-3 flex flex-col items-start md:items-center">
                  <div className="flex flex-col items-start gap-1.5 self-stretch px-3.5 py-[18px] justify-between h-full">
                    <div className="flex self-stretch">
                      <p className="size-textxl text ui w-[82%] md:w-full text-left md:text-center !font-poppins leading-[21px] !text-light_blue-900">
                        Electronic Keyboard
                      </p>
                    </div>
                    <p className="size-textxs text ui w-[96%] !font-poppins leading-[15px] md:w-full text-left md:text-center">
                      Learn to play your favourite song with our electronic keyboard course. Our professionals will walk you through international standard techniques while instilling confidence and skills. Chart the path to a game-changing journey now!
                    </p>
                  </div>
                  <Link to="https://wa.me/19453087641" target='_blank'
                    className="round fill size-xs teal_a400 button ui ml-3 h-[34px] min-w-[118px] !rounded !bg-teal-a400 px-2.5 font-poppins text-[12px] !text-light_blue-900 md:ml-0">
                    Explore Courses
                  </Link>
                </div>
              </div>
              <div className="flex w-[24%] flex-col border-[0.75px] border-solid border-blue-100 bg-gray-100 md:w-full">
                <img src={img_1} alt="Flute" className="h-[120px] object-cover" />
                <div className="mb-3 flex flex-col items-start justify-between h-full md:items-center">
                  <div className="flex flex-col items-start gap-1.5 self-stretch px-3.5 py-[18px]">
                    <div className="flex self-stretch">
                      <p className="size-textxl text ui w-[82%] md:w-full text-left md:text-center !font-poppins leading-[21px] !text-light_blue-900">
                        Flute
                      </p>
                    </div>
                    <p className="size-textxs text ui w-[96%] !font-poppins leading-[15px] md:w-full text-left md:text-center">
                      The flute was first introduced in international classical music then it became a popular learning instrument among enthusiasts. Our teachers will strengthen your foundation and prepare you for global certifications.
                    </p>
                  </div>
                  <Link to="https://wa.me/19453087641" target='_blank'
                    className="round fill size-xs teal_a400 button ui ml-3 h-[34px] min-w-[118px] !rounded !bg-teal-a400 px-2.5 font-poppins text-[12px] !text-light_blue-900 md:ml-0">
                    Explore Courses
                  </Link>
                </div>
              </div>
              <div className="flex w-[24%] flex-col border-[0.75px] border-solid border-blue-100 bg-gray-100 md:w-full">
                <img src={img_4} alt="Piano" className="h-[120px] object-cover" />
                <div className="mb-3 flex flex-col items-start justify-between h-full md:items-center">
                  <div className="flex flex-col items-start gap-1.5 self-stretch px-3.5 py-[18px]">
                    <div className="flex self-stretch">
                      <p className="size-textxl text ui w-[82%] md:w-full text-left md:text-center !font-poppins leading-[21px] !text-light_blue-900">
                        Piano
                      </p>
                    </div>
                    <p className="size-textxs text ui w-[96%] !font-poppins leading-[15px] text-left md:w-full md:text-center">
                      Your journey to becoming an aspiring piano player begins with us. From classical techniques to contemporary style, play your favourite songs with ease under our expert guidance. Transform your vision and become an aspiring piano enthusiast.
                    </p>
                  </div>
                  <Link to="https://wa.me/19453087641" target='_blank'
                    className="round fill size-xs teal_a400 button ui ml-3 h-[34px] min-w-[118px] !rounded !bg-teal-a400 px-2.5 font-poppins text-[12px] !text-light_blue-900 md:ml-0">
                    Explore Courses
                  </Link>
                </div>
              </div>
              <div className="flex w-[24%] flex-col border-[0.75px] border-solid border-blue-100 bg-gray-100 md:w-full">
                <img src={img_3} alt="Tabla" className="h-[120px] object-cover" />
                <div className="mb-3 flex flex-col items-start justify-between h-full md:items-center">
                  <div className="flex flex-col items-start gap-1.5 self-stretch px-3.5 py-[18px]">
                    <div className="flex self-stretch">
                      <p className="size-textxl text ui w-[82%] md:w-full text-left md:text-center !font-poppins leading-[21px] !text-light_blue-900">
                        Tabla
                      </p>
                    </div>
                    <p className="size-textxs text ui w-[96%] !font-poppins leading-[15px] md:w-full text-left md:text-center">
                      The table is known for its intricate rhythms along with expressive sounds played using a variety of techniques. Whether you are a beginner or an experienced professional, we offer a structured program to hone your tabla skills.
                    </p>
                  </div>
                  <Link to="https://wa.me/19453087641" target='_blank'
                    className="round fill size-xs teal_a400 button ui ml-3 h-[34px] min-w-[118px] !rounded !bg-teal-a400 px-2.5 font-poppins text-[12px] !text-light_blue-900 md:ml-0">
                    Explore Courses
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* testimonials section */}
      <div className="bg-color_3">
        <div
          className="h-[478px] py-[74px] md:h-auto md:py-10">
          <div className="flex justify-center">
            <div className="container-xs flex items-start justify-center md:flex-col md:px-5">
              <div className="mt-[76px] md:mt-[0] flex w-[32%] flex-col items-start gap-5 md:w-full md:items-center">
                <h2 className="size-headingxs heading ui !font-poppins !font-bold !text-light_blue-a700">Testimonials</h2>
                <h3 className="size-headingxs heading ui !font-poppins !font-bold leading-[115%] !text-color_2 text-left md:text-center">
                  Learn What Student Experiences<br />Say About Us
                </h3>
                <p className="size-texts text ui w-[80%] !font-asap leading-[150%] !text-color_5 md:w-full text-left md:text-center">
                  We at Mufin Academy leave an indelible impact on the student's journey. Don't trust what we say; hear
                  from those who have explored our courses firsthand today.
                </p>
              </div>
              <div
                className="flex-1 self-center rounded-[18px] bg-color_4 px-11 py-[50px] shadow-xs md:self-stretch md:p-5 w-[50%] md:w-full md:mt-10">
                <div className="flex flex-col items-center">
                  {/* Swiper */}
                  <div className="swiper testimonialSlider">
                    <Swiper
                      modules={[Navigation, Pagination, Scrollbar, A11y]}
                      spaceBetween={50}
                      slidesPerView={1}
                      navigation
                    >
                      <SwiperSlide className='flex-col items-center'>
                        <div
                          className="h-[66px] w-[66px] rounded-[32px] border-[1.5px] border-solid border-light_blue-a700 bg-gray-400 overflow-hidden">
                            <img className="w-full block object-cover" src={testimonial_img} alt="Asha Jadav" />
                        </div>
                        <h4 className="size-headingxs heading ui mt-2.5 !font-nunito !font-bold !text-light_blue-a700">Asha
                        Jadhav
                        </h4>
                        <h6
                          className="size-headingxs heading ui mt-4 self-stretch text-center !font-nunito italic leading-4 !text-color_2">
                          “Joining Mufin Academy has been a game-changing experience for me. Their experts are quite
                          knowledgeable and passionate about music and dance. They have enhanced my skills and
                          confidence
                          within a short period. I definitely recommend the academy to all.”
                        </h6>
                      </SwiperSlide>
                      <SwiperSlide className='flex-col items-center'>
                        <div
                          className="h-[66px] w-[66px] rounded-[32px] border-[1.5px] border-solid border-light_blue-a700 bg-gray-400 overflow-hidden">
                          <img className="w-full block object-cover" src={testimonial_img} alt="Avani Gupta" />
                        </div>
                        <h4 className="size-headingxs heading ui mt-2.5 !font-nunito !font-bold !text-light_blue-a700">Avani
                          Gupta</h4>
                        <h6
                          className="size-headingxs heading ui mt-4 self-stretch text-center !font-nunito italic leading-4 !text-color_2">
                          “The one-on-one sessions have been a life changer for Avni as she acquired her undivided
                          attention. I have even enrolled my younger son in dance classNamees at Mufin Academy. Highly
                          recommend aspiring enthusiasts to join the academy.”
                        </h6>
                      </SwiperSlide>
                      <SwiperSlide className='flex-col items-center'>
                        <div
                          className="h-[66px] w-[66px] rounded-[32px] border-[1.5px] border-solid border-light_blue-a700 bg-gray-400 overflow-hidden">
                          <img className="w-full block object-cover" src={testimonial_img} alt="Jyoti Bhatt" />
                        </div>
                        <h4 className="size-headingxs heading ui mt-2.5 !font-nunito !font-bold !text-light_blue-a700">Jyoti
                          Bhatt
                        </h4>
                        <h6
                          className="size-headingxs heading ui mt-4 self-stretch text-center !font-nunito italic leading-4 !text-color_2">
                          “The academy fosters an environment of growth and inclusivity. Their blend of creativity and
                          professionalism motivates me to extend my boundaries as an artist. I have had the chance to
                          engage with various professionals and represent myself at international levels.”
                        </h6>
                      </SwiperSlide>
                      <SwiperSlide className='flex-col items-center'>
                        <div
                          className="h-[66px] w-[66px] rounded-[32px] border-[1.5px] border-solid border-light_blue-a700 bg-gray-400 overflow-hidden">
                          <img className="w-full block object-cover" src={testimonial_img} alt="Nidha Sharma" />
                        </div>
                        <h4 className="size-headingxs heading ui mt-2.5 !font-nunito !font-bold !text-light_blue-a700">Nidha
                          Sharma
                        </h4>
                        <h6
                          className="size-headingxs heading ui mt-4 self-stretch text-center !font-nunito italic leading-4 !text-color_2">
                          “I was searching for music academies within my region when I came across Mufin Academy. After
                          talking with the teachers and students, I was motivated to join the course. I am immensely
                          grateful to the mentors for offering me constructive feedback at every step, which helped me
                          succeed as a professional.”
                        </h6>
                      </SwiperSlide>
                      <SwiperSlide className='flex-col items-center'>
                        <div
                          className="h-[66px] w-[66px] rounded-[32px] border-[1.5px] border-solid border-light_blue-a700 bg-gray-400 overflow-hidden">
                          <img className="w-full block object-cover" src={testimonial_img} alt="Tamanna Sharma" />
                        </div>
                        <h4 className="size-headingxs heading ui mt-2.5 !font-nunito !font-bold !text-light_blue-a700">
                          Tamanna Sharma
                        </h4>
                        <h6
                          className="size-headingxs heading ui mt-4 self-stretch text-center !font-nunito italic leading-4 !text-color_2">
                          “I started my music lessons in childhood but was unable to continue them. Now, with Mufin
                          Academy, I have fulfilled my dream of becoming a musician. Thanks a lot to the staff for
                          giving me amazing opportunities.”
                        </h6>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* newsletter section */}
      <NewsLetter />
    </div>
  )
}

export default Home