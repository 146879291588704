import React from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import img_info from '../public/images/img_info.svg';
import img_vector_primary_8x4 from '../public/images/img_vector_primary_8x4.svg';
import img_trash from '../public/images/img_trash.svg';
import img_vector_primary_6x8 from '../public/images/img_vector_primary_6x8.svg';
import img_link from '../public/images/img_link.svg';

function Footer() {

  let location = useLocation();

  useEffect(() => {
  }, [location])

  const scroll = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  return (
    <footer className="flex bg-color_3 py-3">
      <div className="mt-[66px] md:mt-[20px] flex w-full flex-col items-center">
        <div className="flex w-[88%] items-center justify-between gap-5 md:w-full md:flex-col md:px-5">
          <p className="size-textxl text ui w-[18%] h-[100%] inline-flex items-center self-end !font-asap leading-[150%] !text-color_5 md:w-full">
            Learn music & dance at your own pace through a personalized adaptive learning model that favors your interest and growth.
          </p>
          <div className="flex w-[72%] items-start justify-center md:w-full sm:flex-col">
            <div className="flex w-[72%] items-center justify-between gap-5 self-center md:w-full md:flex-col">
              <div className="flex w-[32%] flex-col items-start gap-3 self-start md:w-full">
                <h6 className="size-headingmd heading ui !font-nunito uppercase tracking-[0.80px] !text-light_blue-a700">
                  Useful Links
                </h6>
                <ul className="flex flex-col items-start gap-1.5">
                  <li>
                    <Link to="/" onClick={scroll}>
                      <p className="size-textmd heading ui !text-[13.5px]">Home</p>
                    </Link>
                  </li>
                  <li>
                    <Link to="/aboutus" onClick={scroll}>
                      <p className="size-textmd heading ui !text-[13.5px]">About Us</p>
                    </Link>
                  </li>
                  <li>
                    <Link to="/courses" onClick={scroll}>
                      <p className="size-textmd heading ui !text-[13.5px]">Courses</p>
                    </Link>
                  </li>
                  <li>
                    <Link to="/contactus" onClick={scroll}>
                      <p className="size-textmd heading ui !text-[13.5px]">Contact</p>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="flex w-[36%] flex-col items-start gap-3 self-start md:w-full">
                <h6 className="size-headingmd heading ui !font-nunito uppercase tracking-[0.80px] !text-light_blue-a700">
                  Support
                </h6>
                <ul className="flex flex-col items-start gap-1.5">
                  <li>
                    <Link to="/contactus">
                      <p className="size-textmd heading ui !text-[13.5px]">Customer Support</p>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" rel="noreferrer">
                      <p className="size-textmd heading ui !text-[13.5px]">Disclaimer</p>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <p className="size-textmd heading ui !text-[13.5px]">Privacy Policy</p>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <p className="size-textmd heading ui !text-[13.5px]">Terms and Conditions</p>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" rel="noreferrer">
                      <p className="size-textmd heading ui !text-[13.5px]">FAQ</p>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="flex w-[30%] flex-col items-start gap-3 self-start md:w-full">
                <h6 className="size-headingmd heading ui !font-nunito uppercase tracking-[0.80px] !text-light_blue-a700">
                  FOllow Us
                </h6>
                <ul className="flex flex-col items-start gap-1.5">
                  <li>
                    <Link to="#">
                      <div className="flex items-center gap-2">
                        <span className="social-icon rounded-full p-1 bg-black-900 w-[20px] h-[20px] flex items-center justify-center">
                          <img src={img_info} alt="Instagram Icon" className="h-[10px] ml-[1px]" />
                        </span>
                        <p className="size-textmd heading ui !text-[13.5px]">Instagram</p>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="flex items-center gap-2">
                        <span className="social-icon rounded-full p-1 bg-black-900 w-[20px] h-[20px] flex items-center justify-center">
                          <img src={img_vector_primary_8x4} alt="Facebook Icon" className="h-[10px]" />
                        </span>
                        <p className="size-textmd heading ui !text-[13.5px]">Facebook</p>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="flex items-center gap-2">
                        <span className="social-icon rounded-full p-1 bg-black-900 w-[20px] h-[20px] flex items-center justify-center">
                          <img src={img_trash} alt="Twitter Icon" className="h-[10px]" />
                        </span>
                        <p className="size-textmd heading ui !text-[13.5px]">Twitter</p>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="flex items-center gap-2">
                        <span className="social-icon rounded-full p-1 bg-black-900 w-[20px] h-[20px] flex items-center justify-center">
                          <img src={img_vector_primary_6x8} alt="Youtube Icon" className="h-[10px]" />
                        </span>
                        <p className="size-textmd heading ui !text-[13.5px]">Youtube</p>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="flex items-center gap-2">
                        <span className="social-icon rounded-full p-1 bg-black-900 w-[20px] h-[20px] flex items-center justify-center">
                          <img src={img_link} alt="Linkedin Icon" className="h-[10px]" />
                        </span>
                        <p className="size-textmd heading ui !text-[13.5px]">LinkedIn</p>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex w-[26%] flex-col items-start gap-2 self-start md:w-full sm:mt-[30px]">
              <ul className="flex flex-col items-start gap-3 self-stretch">
                <li>
                    <h6
                      className="size-headingmd heading ui !font-nunito uppercase tracking-[0.80px] !text-light_blue-a700">
                      Contact us
                    </h6>
                </li>
                <li>
                    <p className="size-textlg heading ui text-left">12-11-208, Warasiguda,<br />Secunderabad, Telangana, India</p>
                </li>
              </ul>
              <p className="size-textlg heading ui">
                <Link to="mailto:connect@mufinacademy.com">connect@mufinacademy.com</Link>
              </p>
              <p className="size-textlg heading ui">+91 89197 97982</p>
            </div>
          </div>
        </div>
        <div className="mt-[62px] h-[0.75px] w-full self-stretch bg-color_1"></div>
        <div className="container-xs mt-2.5 flex flex-col items-center px-14 md:px-5">
          <p className="size-textlg heading ui !font-asap !font-normal !text-color_1">
            Copyright © 2024 Mufin. All Right Reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer