import React from 'react'

function NewsLetter() {
  return (
    <div
        className="h-[152px] bg-newsletter bg-cover bg-no-repeat opacity-0.99 md:h-auto">
        <div
          className="h-[152px] bg-newsletter bg-cover bg-no-repeat opacity-0.99 md:h-auto">
          <div className="flex justify-center bg-light_blue-900_bc py-11 md:py-5 h-full">
            <div className="container-xs flex items-center justify-center md:flex-col md:px-5">
              <h2 className="size-heading3xl heading ui !font-nunito !text-color_3 sm:text-[32px] md:mb-[15px]">
                Susbcribe Our Newsletter
              </h2>
              <div className="flex flex-1 justify-end md:justify-center gap-3 md:self-stretch sm:flex-col">
                <label
                  className="round fill size-sm color_4 input ui h-[38px] w-[58%] !rounded-[18px] !bg-color_4 px-[22px] text-[13.5px] font-medium !text-color_1 sm:w-full sm:px-5">
                  <input name="Email Field" placeholder="Enter Your E-mail" type="text" />
                </label>
                <button
                  className="fill size-md secondary_1 button ui h-[38px] min-w-[148px] !rounded-[18px] !bg-secondary-1 px-8 font-nunito text-[13.5px] font-bold uppercase tracking-[0.68px] !text-gray-100_fc opacity-0.99 sm:px-5">
                  subscribe
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default NewsLetter